<template>
  <div class="footerWrapper">
    <div class="container">
      <div class="col-lg-12 links">
        <div class="text-center">
          <p>
            <a
              href="https://www.keepcapsfromkids.eu/de"
              style="text-decoration: underline !important;"
            >
              Außer Reichweite von Kindern aufbewahren
            </a>
          </p>
          <img
            src="@/assets/images/png/footer-image.png"
            alt="Außer Reichweite von Kindern aufbewahren"
            style="max-width: 100%; max-height: 80px"
          >
        </div>
        <div class="row justify-content-center my-3">
          <!-- <div class="col-lg-10 my-4">
            <p class="extra-text">
              1Energiekostenersparnis basierend auf 166 Wäschen pro Jahr und durchschnittlichen Energiekosten.
              <br>
              2Europäischer Durchschnittswert des CO2-Fußabdrucks des Wäschewaschens.
            </p>
          </div> -->
          <div class="col-lg-8">
            <ul class="links bottom-links">
              <li>
                <a
                  href="https://termsandconditions.pg.com/de-de/"
                  target="_blank"
                >
                  {{ $t('Geschäftsbedingungen') }}
                </a>
              </li>
              <li>
                <a
                  href="https://preferencecenter.pg.com/de-de/"
                  target="_blank"
                >
                  {{ $t('Meine Daten') }}
                </a>
              </li>
              <li>
                <a
                  href="https://privacypolicy.pg.com/de-de/"
                  target="_blank"
                >
                  {{ $t('Datenschutz') }}
                </a>
              </li>
              <li>
                <a href="https://www.ariel.de/de-de/contact-us" target="_blank">
                  {{ $t('Kontakt') }}
                </a>
              </li>
              <li>
                <a
                  href="https://de.pg.com/impressum/"
                  target="_blank"
                >
                  {{ $t('Impressum') }}
                </a>
              </li>
              <li>
                © 2024 Procter & Gamble
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-3 powered d-none">
        <a
          href="https://www.justsnap.de/"
          target="_blank"
          style="text-decoration: none;"
        >
          Powered by
          <img
            src="@/assets/images/png/js-logo.png"
            alt="jstnp"
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent"
};
</script>

<style lang="scss" scoped>
@import "@/mixins/style/_color";
$tablet : 991px;

.footerWrapper {
  color: #FFF;
  background-color: $color-1;
  width: 100%;
  padding: 30px 20px;

  .container {
    display: flex;
    justify-content: space-between;
  }
}
.links {
  margin: 0;
  padding: 0;
  list-style: none;
  a {
    color: #fff !important;
    text-decoration: none !important;
  }

  &.bottom-links {
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
    }

    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      
      li {
        border: none !important;
      }
    }
    li {
      margin-right: 30px;
      margin-top: 10px;
    }
  }
}

.powered {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 30px;
  margin-left: 30px;

  img {
    width: 50px;
    height: 50px;
  }

  @media (max-width: 767px) {
    display: block !important;
  }
}

.extra-text {
  text-align: left;
}

@media (max-width: 767px) {
  .footerWrapper {
    font-size: 16px;
    padding: 30px 5px;
    .container {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 20px;
    }
  }
  .links {
    text-align: center;
  }

  .extra-text {
    text-align: center;
  }

  .bottom-links {
    li {
      display: inline;
      &:not(:last-child) {
        margin-right: 10px;
        padding-right: 10px;
        border-right: 1px solid #fff;
      }
    }
  }

  .powered {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;

    img {
      width: 40px;
      height: 40px;
    }

    p {
      display: block !important;
    }
  }
}

</style>
